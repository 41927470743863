<template>
  <ItemForm
    :title="`New ${config.name}`"
    :config="config"
    :submit-handler="config.createHandler || submitHandler"
    :submit-message="'Creating ' + config.name.toLowerCase() + '...'"
    :submit-success-message="
      'Successfully created ' + config.name.toLowerCase() + '.'
    "
    :allow-changing-id="true"
    v-on="$listeners"
  />
</template>

<script>
import ItemForm from './ItemForm';
import api from '../../utils/api-axios';
import configs from './configs';
import toFormData from '../../utils/to-form-data';

export default {
  components: {
    ItemForm,
  },
  computed: {
    config() {
      return {
        ...configs[this.$route.params.type],
        ...(configs[this.$route.params.type].overrides || {}).createPage,
      };
    },
  },
  methods: {
    submitHandler(data) {
      const requestBody = this.config.formData ? toFormData(data) : data;
      return api.post(this.config.apiUrl, requestBody);
    },
  },
};
</script>

<style lang="scss" scoped></style>
